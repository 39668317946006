




















import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default defineComponent({
  name: 'UCIrvineChem51LBELN7',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      attachments: [] as File[],
    };
  },
});
